import React from 'react';
import { MenuDatas } from '../components/Datas';
import { Link } from 'react-router-dom';

function Sidebar() {
  // active link
  const currentPath = (path) => {
    const currentPath =
      window.location.pathname.split('/')[1] === path.split('/')[1];
    if (currentPath) {
      return path;
    }
    return null;
  };

  return (
    <div style={{scrollbarWidth:'none'}} className="bg-white xl:shadow-lg py-6 px-4 xl:h-screen h-screen w-[85px] overflow-y-scroll border-border no-scrollbar">
      <Link to="/">
        <div className="flex flex-row items-end">
          <h1 className="text-[16px] text-black font-[600]" >avijo</h1><h6 className="text-[8px] font-[500] text-[#12CDB7] italic">Alpha</h6>
        </div>
        {/* <img
          src="/images/logo.png"
          alt="logo"
          className="w-2/4 h-12 ml-4 object-contain"
        /> */}
        
      </Link>
      <div className="flex-colo gap-2 mt-4">
        {MenuDatas.map((item, index) => (
          <Link
            to={item.path}
            key={index}
            className={`
            flex flex-col gap-4 transitions group items-center w-full p-1 rounded-lg hover:bg-text`}
          >
            <item.icon
              className={`${currentPath(item.path) === item.path ? 'text-[#12CDB7]' : 'text-gray-500'
                } h-[16] w-[16px]`}
            />
            <p
              className={`text-[10px] font-medium group-hover:text-subMain ${currentPath(item.path) === item.path
                ? 'text-[#12CDB7]'
                : 'text-gray-500'
                }`}
            >
              {item.title}
            </p>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
