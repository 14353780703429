import React from "react";
import PrescriptionHeader from "../components/PrescriptionHeader";
import Layout from "../Layout";
import { AiOutlineChrome, AiOutlineCloud, AiOutlineCloudDownload, AiOutlineEdit, AiOutlinePaperClip, AiOutlineWhatsApp, AiTwotonePrinter } from "react-icons/ai";
import { BsCapsulePill, BsLink, BsNewspaper, BsSend } from "react-icons/bs";
import { TbFilterEdit } from "react-icons/tb";
import { FaCheckSquare, FaEdit, FaRegCheckSquare } from "react-icons/fa";

export default function PrescriptionDetail() {

    return (
        <Layout>
            <div>
                <PrescriptionHeader show={false} />
                <div className="flex flex-row items-start justify-between pl-[2%] pr-[2%] bg-[white] mt-[3%] mb-[3%] pb-[3%]">
                    <FirstSect />
                    <SecondSec />
                </div>
                <div className="flex flex-row justify-end w-full ">
                <div className="flex flex-row items-center justify-between bg-[white] p-4 pl-6 pr-6 mb-[5%] w-[80%] ">
                    <div className="flex flex-row items-center p-2 border border-[#39D2C0] rounded-[5px] pl-4 pr-4">
                        <FaCheckSquare className="text-[#39D2C0]"/>
                        <span className="text-[16px] text-[Gilroy-SemiBold] text-[#39D2C0] ml-2">Monetize Rx</span>
                    </div>
                    <div className="flex flex-row items-center p-2 border border-[#39D2C0] rounded-[5px] pl-4 pr-4">
                        {/* <FaCheckSquare className="text-[#39D2C0]"/> */}
                        <span className="text-[16px] text-[Gilroy-SemiBold] text-[#39D2C0] ml-2">Edit Prescription</span>
                    </div>
                    <div className="flex flex-row items-center p-2 border border-[#39D2C0] rounded-[5px] pl-4 pr-4">
                        <BsCapsulePill className="text-[#39D2C0]"/>
                        <span className="text-[16px] text-[Gilroy-SemiBold] text-[#39D2C0] ml-2">Order Medicines</span>
                    </div>
                    <div className="flex flex-row items-center p-2 border border-[#39D2C0] rounded-[5px] pl-4 pr-4">
                        <AiTwotonePrinter className="text-[#39D2C0]"/>
                        <span className="text-[16px] text-[Gilroy-SemiBold] text-[#39D2C0] ml-2">Print</span>
                    </div>
                    <div className="flex flex-row items-center p-2 bg-[#39D2C0] rounded-[5px] pl-4 pr-4">
                        {/* <FaCheckSquare className="text-[#39D2C0]"/> */}
                        <span className="text-[16px] text-[Gilroy-SemiBold] text-white ml-2">Send Rx & End Visit</span>
                    </div>
                </div>
            </div>
            </div>
        </Layout>
    )
}

const Option = (props) => {
    return (
        <div className="flex flex-row p-3 pl-4 pr-4 rounded-full bg-[#39D2C0]">
            {props.icon}
            <span className="text-[14px] text-[Gilroy-Medium] text-[white] ml-2">{props.text}</span>
        </div>
    )
}

const FirstSect = () => {
    return (
        <div className="w-1/2 h-full justify-center items-center flex flex-colo mt-[10%]">
            <div className="flex flex-row items-center">
                <AiOutlineWhatsApp className="h-[32px] w-[32px]" />
                <span className="text-[18px] text-[Gilroy-SemiBold] text-[black] ml-4">Prescription Saved Successfully.</span>
                <span className="text-[16px] text-[Gilroy-Medium] text-[#39D2C0]"> +91 4759573857537</span>
                <AiOutlineEdit className="h-[16px] w-[16px] text-[#39D2C0]" />
            </div>
            <div className="flex flex-row justify-between items-center w-[85%] mt-[5%]">
                <Option
                    icon={<AiOutlinePaperClip className="text-[white] h-[20px] w-[20px]" />}
                    text="Send Attachment" />
            </div>
            <div className="flex flex-row justify-between items-center w-[85%] mt-4">
                <Option
                    icon={<AiTwotonePrinter className="text-[white] h-[20px] w-[20px]" />}
                    text="Print" />
                <Option
                    icon={<AiOutlineCloudDownload className="text-[white] h-[20px] w-[20px]" />}
                    text="Download" />
                <Option
                    icon={<BsSend className="text-[white] h-[20px] w-[20px]" />}
                    text="Send Via Own Whatsapp" />
            </div>
            <div className="flex flex-row justify-start items-center w-[85%] mt-4">
                <Option
                    icon={<BsLink className="text-[white] h-[20px] w-[20px]" />}
                    text="Send Payment Link" />
                <div className="ml-4">
                    <Option
                        icon={<BsNewspaper className="text-[white] h-[20px] w-[20px]" />}
                        text="Bill Patient" />
                </div>
            </div>
            <div className="flex flex-row justify-start items-center w-[85%] mt-4">
                <Option
                    icon={<AiOutlineChrome className="text-[white] h-[20px] w-[20px]" />}
                    text="Send Google Review Link" />
                <div className="ml-4">
                    <Option
                        icon={<FaEdit className="text-[white] h-[20px] w-[20px]" />}
                        text="Request Vitals" />
                </div>
            </div>
            <div className="flex flex-row items-center w-[80%] mt-[5%]">
                <div className="border-b-2 border-[black] pb-2">
                    <span className="text-[#676767] text-[16px] text-[Gilroy-SemiBold]">New Template</span>
                </div>
                <div className="ml-4 pb-2">
                    <span className="text-[#676767] text-[16px] text-[Gilroy-SemiBold]">Update existing Template</span>
                </div>
            </div>
            <div className="flex flex-row items-center w-[80%] justify-between mt-4">
                <input
                    className="border border-[black] rounded-[5px] w-[80%] h-[40px] text-[16px] text-[Gilroy-SemiBold] pl-2"
                    placeholder="Template name" />
                <div className="h-[40px] w-[15%] bg-[#39D2C0] rounded-[5px] items-center justify-center flex flex-colo">
                    <span className="text-[white]">Save</span>
                </div>
            </div>
        </div>
    )
}

const SecondSec = () => {
    return (
        <div className="w-1/2 h-full justify-center items-center flex flex-col mt-[3%]">
            <div className="flex flex-row w-[90%] items-start justify-between border-b-2 pb-4 border-[black]">
                <div className="p-6 bg-[#39D2C026]">
                    <span className="text-[35px] text-[Gilroy-Medium] text-[#39D2C0]">avijo</span>
                </div>
                <div className="flex flex-col items-start justify-center">
                    <span className="text-[14px] text-[Gilroy-SemiBold] text-[black]">Dr. Bhaskar Chaudhary</span>
                    <span className="text-[12px] text-[Gilroy-SemiBold] text-[#575757]">MBBS/MD</span>
                    <span className="text-[12px] text-[Gilroy-SemiBold] text-[#575757]">General Physician</span>
                </div>
                <div className="flex flex-col items-start justify-center">
                    <span className="text-[14px] text-[Gilroy-SemiBold] text-[black]">Metropolis Vidhyavihar</span>
                    <span className="text-[12px] text-[Gilroy-SemiBold] text-[black]">Kohinoor Mall, VidhyaVihar, 69970</span>
                </div>
            </div>
            <div className="flex flex-row w-[90%] items-center justify-between mt-4">
                <div className="flex flex-row items-center">
                    <span className="text-[18px] text-[Gilroy-SemiBold] text-[#39D2C0]">Imtiyaz</span>
                    <span className="ml-2 text-[14px] text-[Gilroy-SemiBold] text-[black]">Male, 32 year(s), +5768683454</span>
                </div>
                <span className="text-[16px] text-[Gilroy-SemiBold] text-[#39D2C0]">12/04/2024, 12:24 PM </span>
            </div>
            <div className="flex flex-row items-center w-[90%]">
                <span className="text-[18px] text-[Gilroy-SemiBold] text-[#39D2C0]">UHID</span>
                <span className="ml-2 text-[14px] text-[Gilroy-SemiBold] text-[black]">eka101</span>
            </div>
            <div className="flex flex-row items-center w-[90%]">
                <span className="text-[18px] text-[Gilroy-SemiBold] text-[#39D2C0]">Chief Complaints</span>
                <span className="ml-2 text-[14px] text-[Gilroy-SemiBold] text-[black]">Chest pain (Since 1 week)</span>
            </div>
            <div className="flex flex-row items-center w-[90%]">
                <span className="text-[18px] text-[Gilroy-SemiBold] text-[#39D2C0]">Diagnosis</span>
                <span className="ml-2 text-[14px] text-[Gilroy-SemiBold] text-[black]">Typhoid Fever</span>
            </div>
            <div className="w-[90%] mt-[3%]">
                {/* Heading Row */}
                <div className="flex justify-between items-center mb-2">
                    <span className="text-[14px] text-[Gilroy-SemiBold] text-[black] w-[10%]"></span>
                    <span className="text-[15px] text-[Gilroy-SemiBold] text-[black] w-[20%]">Medications</span>
                    <span className="text-[15px] text-[Gilroy-SemiBold] text-[black] w-[20%]">Frequency</span>
                    <span className="text-[15px] text-[Gilroy-SemiBold] text-[black] w-[20%]">Duration</span>
                    <span className="text-[15px] text-[Gilroy-SemiBold] text-[black] w-[20%]">Remarks</span>
                </div>

                {/* Data Row */}
                <div className="flex flex-row items-center justify-between border-b-2 pb-4 border-[black]">
                    <div className="flex flex-col justify-center items-center h-[50px] w-[10%]">
                        <span className="text-[14px] text-[Gilroy-Medium] text-[black]">1</span>
                    </div>
                    <div className="flex flex-col items-start justify-center w-[20%]">
                        <span className="text-[10px] text-[Gilroy-SemiBold] text-[black]">Telma H tablet</span>
                        <span className="text-[10px] text-[Gilroy-SemiBold] text-[#575757]">Telmisaartan (40mg) Hydrochloronic (125mg)</span>
                    </div>
                    <div className="flex flex-col items-start justify-center w-[20%]">
                        <span className="text-[10px] text-[Gilroy-SemiBold] text-[black]">1-1-1-1</span>
                        <span className="text-[10px] text-[Gilroy-SemiBold] text-[#575757]">After Meal</span>
                    </div>
                    <div className="flex flex-col items-start justify-center w-[20%]">
                        <span className="text-[10px] text-[Gilroy-SemiBold] text-[#575757]">60 Days</span>
                    </div>
                    <div className="flex flex-col items-start justify-center w-[20%]">
                        <span className="text-[10px] text-[Gilroy-SemiBold] text-[#575757]">60 Days</span>
                    </div>
                </div>
            </div>
            <div className="w-[90%] mt-[5%]">
                <span className="text-[18px] text-[Gilroy-SemiBold] text-[#39D2C0]">Advice</span>
                <ul className="list-disc pl-6">
                    <li className="text-[16px] text-[Gilroy-SemiBold] text-[black]">Lorem ipsum dummy text </li>
                    <li className="text-[16px] text-[Gilroy-SemiBold] text-[black]">Lorem ipsum dummy text </li>
                    <li className="text-[16px] text-[Gilroy-SemiBold] text-[black]">Lorem ipsum dummy text </li>
                </ul>
            </div>
            <div className="flex flex-row items-center mt-4 w-[90%]">
                <span className="text-[18px] text-[Gilroy-SemiBold] text-[#39D2C0]">Follow Up</span>
                <span className="ml-4 text-[14px] text-[Gilroy-SemiBold] text-[black]"><span className="text-[#9C9C9C]">Visit on</span> Monday, May 12 2024</span>
            </div>
        </div>
    )
}