import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const CollapsibleDropdown = ({ label, content, options, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen((prev) => !prev);
    };

    const handleOptionClick = (event, option) => {
        event.stopPropagation(); // Prevent parent click event
        onSelect(option);
        setIsOpen(false);
    };

    return (
        <div className="d-flex flex-column w-full">
            <span className="text-[12px] text-gray-500 font-semibold">{label}</span>
            <div
                className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 flex flex-col cursor-pointer"
                onClick={handleToggle}
            >
                <div className="flex flex-row items-center justify-between">
                    <span className="text-[14px] text-black pl-2 pt-2.5">
                        {content || "Select an option"}
                    </span>
                    <div className="pr-2 pt-3">
                        {isOpen ? (
                            <FaChevronUp className="text-[15px]" />
                        ) : (
                            <FaChevronDown className="text-[15px]" />
                        )}
                    </div>
                </div>
                {isOpen && (
                    <div className="mt-2 bg-white border border-gray-300 rounded-[10px] flex flex-col h-[200px]">
                        {options.map((option, index) => (
                            <div
                                key={index}
                                className="px-2 py-1 text-[14px] text-gray-700 hover:bg-gray-100 h-[30px]"
                                onClick={(event) => handleOptionClick(event, option)}>
                                <span>{option}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default CollapsibleDropdown;
