import React from "react";

export default function PrescriptionHeader(props) {

    return (
        <div className="flex flex-row items-center justify-between bg-[white] w-full p-1 pr-4">
            <div className="flex flex-row items-center">
                <img src={require('../Assets/images/blackLeft.png')} className="h-[26px] w-[26px] ml-4" />
                <img src={require('../Assets/images/profile.png')} className="h-[30px] w-[30px] rounded-[58px] ml-6" />
                <div className="ml-4">
                    <div className="flex flex-row items-center">
                        <h4 className="text-[24px] text-[Gilroy-SemiBold] text-[black]">Imtiyaz</h4>
                        <span className="ml-2 text-[14px] text-[Gilroy-SemiBold]">31y.9mM</span>
                    </div>
                    <div className="flex flex-row items-center justify-center">
                        <h4 className="text-[14px] text-[Gilroy-SemiBold]">eka101  .  6364766400</h4>
                        <img src={require('../Assets/images/blackDown.png')} className="h-[18px] w-[18px] ml-2" />
                    </div>
                </div>
                <div className="flex flex-row items-center ml-6">
                    {props.show && <img src={require('../Assets/images/edit.png')} className="h-[40px] w-[40px] ml-2" />}
                    <img src={require('../Assets/images/video.png')} className="h-[40px] w-[40px] ml-2" />
                    {props.show && <img src={require('../Assets/images/link.png')} className="h-[40px] w-[40px] ml-2" />}
                </div>
                {props.show && <div className="flex flex-row items-center ml-6">
                    <img src={require('../Assets/images/dots.png')} className="h-[22px] w-[22px] ml-4" />
                    <span className="text-[12px] text-[Gilroy-SemiBold] ml-2">Patient Overview</span>
                </div>}
                {props.show && <div className="flex flex-row items-center ml-6">
                    <img src={require('../Assets/images/Prescription.png')} className="h-[14px] w-[14px] mb-2" />
                    <span className="text-[16px] text-[Gilroy-SemiBold] ml-1 border-b-2 border-[black] pb-2">Prescription Pad</span>
                </div>}
                {props.show && <div className="flex flex-row items-center ml-6">
                    <img src={require('../Assets/images/t.png')} className="h-[30px] w-[30px] ml-4" />
                    <span className="text-[12px] text-[Gilroy-SemiBold] ml-2">Templates</span>
                </div>}
            </div>
            <div className="flex flex-row items-center ml-6">
                <img src={require('../Assets/images/settings.png')} className="h-[30px] w-[30px] ml-4" />
                <span className="text-[12px] text-[Gilroy-SemiBold] ml-2">Confgure your pad</span>
            </div>
        </div>
    )
}