import React, { useState } from "react";
import Layout from "../Layout";
import PrescriptionHeader from "../components/PrescriptionHeader";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ToggleButton from "../components/ToggleButton";
import Switch from "react-switch";


export default function Prescription() {

    const [switch1, setSwitch] = useState(false);

    const handleChange = () =>{
        setSwitch(!switch1);
    }

    return (
        <Layout>
            <div>
                <PrescriptionHeader show={true} />
                <Vitals />
                <Symptoms />
                <Diagnosis />
                <Medications />
                <Notes />
                <div className="flex flex-row items-start justify-between pl-[2%] pr-[2%] bg-[white] mt-[5%]">
                    <FollowUp />
                    <Advices />
                </div>
                <div className="p-2 flex flex-row items-center justify-between mt-[3%] bg-[white] mb-[5%]">
                    <div className="p-[2%] flex flex-row items-center">
                        <img
                            src={require('../Assets/images/notes.png')}
                            className="h-[30px] w-[30px]"
                        />
                        <h2 className="text-[18px] text-[Gilroy-SemiBold] ml-4">Short Procedure</h2>
                    </div>
                    <div className="p-[2%] flex flex-row items-center">
                        <span className="text-[16px] text-[Gilroy-SemiBold] mr-4">Print on prescription</span>
                        <Switch onColor="#0097DB" onChange={handleChange} checked={switch1}/>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

const VitalInput = (props) => {
    return (
        <div>
            <div className="flex flex-row items-center">
                <img src={require(`../Assets/images/${props.image}.png`)} className="h-[30px] w-[30px]" />
                <span className="text-[16px] text-[Gilroy-SemiBold] ml-4">{props.Text}</span>
            </div>
            <div className=" flex flex-row border p-1 mt-4 h-[40px] items-center rounded-[5px] w-[220px]">
                <input
                    className="w-[80%]"
                    type="text"
                    value={props.value}
                    onChange={props.onChange}
                />
                <span className="pr-2 text-[#CDCED0] w-[20%] text-end text-[12px]">{props.unit}</span>
            </div>
        </div>
    )
}

const Vitals = () => {
    return (
        <div className="mt-[3%] bg-[white]">
            <div className="p-[2%] flex flex-row items-center">
                <img src={require('../Assets/images/vitals.png')} className="h-[30px] w-[30px]" />
                <h2 className="text-[24px] text-[Gilroy-SemiBold] ml-4">vitals</h2>
            </div>
            <div className="pl-[4%] pr-[4%] p-[1%] flex flex-row items-center justify-between">
                <VitalInput Text="Body Temperature" image="temperature" unit="F" />
                <VitalInput Text="Body height" image="height" unit="Cms" />
                <VitalInput Text="Body mass index" image="mass" unit="kg/m2" />
            </div>
            <div className="pl-[4%] pr-[4%] p-[1%] flex flex-row items-center justify-between">
                <VitalInput Text="Body weight" image="weight" unit="Kg/s" />
            </div>
        </div>
    )
}

const Symptoms = () => {
    return (
        <div className="mt-[3%] bg-[white] items-center pb-4">
            <div className="p-[2%] flex flex-row items-center justify-between">
                <div className="p-[2%] flex flex-row items-center">
                    <img
                        src={require('../Assets/images/symptom.png')}
                        className="h-[30px] w-[30px]"
                    />
                    <h2 className="text-[24px] text-[Gilroy-SemiBold] ml-4">Symptoms</h2>
                </div>
                <div className="p-[2%] flex flex-row items-center">
                    <img
                        src={require('../Assets/images/file.png')}
                        className="h-[20px] w-[20px]"
                    />
                    <img
                        src={require('../Assets/images/Tsx.png')}
                        className="h-[18px] w-[22px] ml-4"
                    />
                </div>
            </div>
            <div className="flex flex-row border p-1 h-[40px] items-center justify-center rounded-[5px] w-[95%] mx-auto">
                <img src={require('../Assets/images/search.png')} className="text-[#CDCED0] h-[20px] w-[20px] mr-4" />
                <input
                    className="w-full text-start"
                    type="text"
                    placeholder="Start typing Symptoms/Chief Complaints"
                />
            </div>
        </div>
    )
}


const Diagnosis = () => {
    return (
        <div className="mt-[3%] bg-[white] items-center pb-4">
            <div className="p-[2%] flex flex-row items-center justify-between">
                <div className="p-[2%] flex flex-row items-center">
                    <img
                        src={require('../Assets/images/diagnosis.png')}
                        className="h-[30px] w-[30px]"
                    />
                    <h2 className="text-[24px] text-[Gilroy-SemiBold] ml-4">Diagnosis</h2>
                </div>
                <div className="p-[2%] flex flex-row items-center">
                    <img
                        src={require('../Assets/images/file.png')}
                        className="h-[20px] w-[20px]"
                    />
                    <img
                        src={require('../Assets/images/Tsx.png')}
                        className="h-[18px] w-[22px] ml-4"
                    />
                </div>
            </div>
            <div className="flex flex-row border p-1 h-[40px] items-center justify-center rounded-[5px] w-[95%] mx-auto">
                <img src={require('../Assets/images/search.png')} className="text-[#CDCED0] h-[20px] w-[20px] mr-4" />
                <input
                    className="w-full text-start"
                    type="text"
                    placeholder="Start typing diagnosis"
                />
            </div>
        </div>
    )
}


const Medications = () => {
    return (
        <div className="mt-[3%] bg-[white] items-center pb-4">
            <div className="p-[2%] flex flex-row items-center justify-between">
                <div className="p-[2%] flex flex-row items-center">
                    <img
                        src={require('../Assets/images/medication.png')}
                        className="h-[30px] w-[30px]"
                    />
                    <h2 className="text-[24px] text-[Gilroy-SemiBold] ml-4">Medications</h2>
                </div>
                <div className="p-[2%] flex flex-row items-center">
                    <img src={require('../Assets/images/Info.png')} className="h-[20px] w-[20px] mr-2" />
                    <span className="text-[12px] text-[Gilroy-Medium]">Delivery Pincode:</span>
                    <span className="text-[12px] text-[Gilroy-Medium] text-[#39D2C0] ml-2 pl-2 pr-2 rounded-full border border-[#39D2C0]">Pincode</span>
                    <div className="border pl-2 pr-2 rounded-full ml-2 border-[black] flex flex-row items-center">
                        <img src={require('../Assets/images/check.png')} className="h-[14px] w-[14px] mr-1" />
                        <span className="text-[11px] text-[Gilroy-Medium] text-[black] mr-1">Check</span>
                    </div>
                    <img src={require('../Assets/images/info2.png')} className="h-[20px] w-[20px] mr-2 ml-4" />
                    <span className="text-[12px] text-[Gilroy-Medium]">Default instructions: Hindi</span>
                    <img src={require('../Assets/images/blackDown.png')} className="h-[16px] w-[16px] ml-4" />
                </div>
                <div className="p-[2%] flex flex-row items-center">
                    <img
                        src={require('../Assets/images/blackFile.png')}
                        className="h-[20px] w-[20px]"
                    />
                    <img
                        src={require('../Assets/images/blackTdx.png')}
                        className="h-[18px] w-[22px] ml-4"
                    />
                </div>
            </div>
            <div className="flex flex-row border p-1 h-[40px] items-center justify-center rounded-[5px] w-[95%] mx-auto">
                <img src={require('../Assets/images/search.png')} className=" h-[20px] w-[20px] mr-4" />
                <input
                    className="w-full text-start"
                    type="text"
                    placeholder="Start typing Medications"
                />
            </div>
        </div>
    )
}


const Notes = () => {
    return (
        <div className="mt-[3%] bg-[white] items-center pb-4">
            <div className="p-[2%] flex flex-row items-center justify-between">
                <div className="p-[2%] flex flex-row items-center">
                    <img
                        src={require('../Assets/images/notes.png')}
                        className="h-[30px] w-[30px]"
                    />
                    <h2 className="text-[24px] text-[Gilroy-SemiBold] ml-4">Notes</h2>
                </div>
                <div className="p-[2%] flex flex-row items-center">
                    <img
                        src={require('../Assets/images/blackFile.png')}
                        className="h-[20px] w-[20px]"
                    />
                    <img
                        src={require('../Assets/images/blackTdx.png')}
                        className="h-[18px] w-[22px] ml-4"
                    />
                </div>
            </div>
            <div className="flex flex-row border p-1 h-[40px] items-center justify-between rounded-[5px] w-[95%] mx-auto">
                <input
                    className="w-[40%] text-start pl-4"
                    type="text"
                    placeholder="NOTICE FOR PATIENT(TREATMENT/SURGICAL/OTHERS)"
                />
                <span className="text-[#676767] text-[16px] text-[Gilroy-SemiBold]">PRIVATE NOTES</span>
                <div className="flex flex-row items-center">
                    <img src={require('../Assets/images/info2.png')} className="h-[20px] w-[20px] mr-2" />
                    <span className="text-[#676767] text-[16px] text-[Gilroy-SemiBold]">These will not be printed</span>
                </div>
            </div>
        </div>
    )
}

const FollowUp = () => {
    return (
        <div className="mt-[3%] bg-[white] items-center pb-4 w-[48%] justify-center mb-[5%] shadow-lg rounded-sm">
            <div className="p-[2%] flex flex-row items-center justify-between">
                <div className="p-[2%] pl-6 flex flex-row items-center">
                    <img
                        src={require('../Assets/images/followUp.png')}
                        className="h-[30px] w-[30px]"
                    />
                    <h2 className="text-[24px] text-[Gilroy-SemiBold] ml-4">Follow up</h2>
                </div>
            </div>
            <div className="flex flex-row p-1 h-[40px] items-center justify-center w-[90%] mx-auto">
                <div className="flex flex-row border p-1 h-[40px] items-center justify-center rounded-[5px] w-full mx-auto">
                    <input
                        className="w-full text-start pl-2"
                        type="text"
                        placeholder="Start typing Medications"
                    />
                    <img src={require('../Assets/images/cross.png')} className=" h-[20px] w-[20px] mr-2" />
                </div>
                <img src={require('../Assets/images/calendar.png')} className=" h-[20px] w-[20px] ml-4" />
            </div>
            <div className="w-[90%] flex flex-row justify-between items-center mx-auto mt-4">
                <span className="text-[#676767] text-[16px] text-[Gilroy-SemiBold]">Sunday, 12 May 24</span>
                <div className="flex flex-row items-center">
                    <img src={require('../Assets/images/tick.png')} className=" h-[20px] w-[20px] mr-2" />
                    <span className="text-[#676767] text-[16px] text-[Gilroy-SemiBold]">Auto Fill from Rx</span>
                </div>
            </div>
            <div className="w-[90%] h-[200px] mx-auto flex items-center mt-4">
                <textarea
                    className="w-full text-start pl-2 border"
                    type="text"
                    placeholder="Start typing Medications"
                />
            </div>
        </div>
    )
}


const Advices = () => {
    return (
        <div className="mt-[3%] bg-[white] items-center pb-[5%] w-[48%] justify-center mb-[5%] shadow-lg rounded-sm pt-4">
            <div className="p-[2%] flex flex-row items-center justify-between">
                <div className="p-[2%] pl-6 flex flex-row items-center">
                    <img
                        src={require('../Assets/images/advice.png')}
                        className="h-[30px] w-[30px]"
                    />
                    <h2 className="text-[24px] text-[Gilroy-SemiBold] ml-4">Advices</h2>
                </div>
                <div className="p-[2%] pl-6 flex flex-row items-center justify-between w-[45%]">
                    <div className="flex flex-row items-center">
                        <img src={require('../Assets/images/tick.png')} className=" h-[18px] w-[18px] mr-2" />
                        <span className="text-[#676767] text-[14px] text-[Gilroy-SemiBold]">Auto Fill from Rx</span>
                    </div>
                    <div className="flex flex-row items-center">
                        <img
                            src={require('../Assets/images/eye.png')}
                            className="h-[16px] w-[16px]"
                        />
                        <span className="text-[16px] text-[Gilroy-SemiBold] ml-2">hi</span>
                    </div>
                </div>
            </div>
            <div className="w-full mb-4 border">
                <ReactQuill
                    className="w-full"
                    // value={value}
                    // onChange={setValue}
                    theme="snow"
                />
            </div>
            <div className="mt-[5%]">
                <div className="flex flex-row items-center w-[70%] ml-4">
                    <img
                        src={require('../Assets/images/box.png')}
                        className="h-[18px] w-[18px]"
                    />
                    <span className="text-[12px] text-[Gilroy-SemiBold] ml-2">Advised Home isolation and treatment of now. Warning signs explained and revew sos</span>
                </div>
                <div className="flex flex-row items-center w-[70%] ml-4 mt-[3%]">
                    <img
                        src={require('../Assets/images/box.png')}
                        className="h-[18px] w-[18px]"
                    />
                    <span className="text-[12px] text-[Gilroy-SemiBold] ml-2">Steaming gargling</span>
                </div>
                <div className="flex flex-row items-center w-[70%] ml-4 mt-[3%]">
                    <img
                        src={require('../Assets/images/box.png')}
                        className="h-[18px] w-[18px]"
                    />
                    <span className="text-[12px] text-[Gilroy-SemiBold] ml-2">Dr. whatsapp number 72587585996</span>
                </div>
            </div>
        </div>
    )
}