import React from 'react';
import { MdOutlineCloudDownload } from 'react-icons/md';
import { toast } from 'react-hot-toast';
import { BiPlus } from 'react-icons/bi';
import Layout from '../Layout';
import { Button } from '../components/Form';
import { DoctorsTable, FacilityTable } from '../components/Tables';
import { doctorsData } from '../components/Datas';
import { useNavigate } from 'react-router-dom';
import AddDoctorModal from '../components/Modals/AddDoctorModal';
import AddFacilityModal from '../components/Modals/AddFacilityModal';


const facilityData = [
  {
    id:0,
    name:'Micheal',
    image:require('../Assets/images/facility1.png'),
    invitationFor:'Partnership',
    type:'Labs & Pharmacy',
    email:'example@abdm'
  },
  {
    id:1,
    name:'Micheal',
    image:require('../Assets/images/facility2.png'),
    invitationFor:'Ownership',
    type:'Riadiology',
    email:'example@gmail.com'
  },
  {
    id:2,
    name:'Micheal',
    image:require('../Assets/images/facility3.png'),
    invitationFor:'Partnership',
    type:'Clinics',
    email:'example@abdm'
  },
  {
    id:3,
    name:'Micheal',
    image:require('../Assets/images/facility3.png'),
    invitationFor:'Partnership',
    type:'Clinics',
    email:'example@gmail.com'
  }
]



function Facility() {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const preview = (data) => {
    navigate(`/doctors/preview/${data.id}`);
  };

  return (
    <Layout>
      {
        // add doctor modal
        isOpen && (
          <AddFacilityModal
            closeModal={onCloseModal}
            isOpen={isOpen}
            doctor={true}
            datas={null}
          />
        )
      }

      {/* add button */}
      <button
        onClick={() => setIsOpen(true)}
        className="w-16 animate-bounce h-16 border border-border z-50 bg-subMain text-white rounded-full flex-colo fixed bottom-8 right-12 button-fb"
      >
        <BiPlus className="text-2xl" />
      </button>
      <div className="flex flex-row items-end mb-6">
        <h1 className="text-[38px] text-black font-[600]" >avijo</h1><h6 className="text-[24px] font-[500] text-[#12CDB7] italic">Alpha</h6>
      </div>

      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        data-aos-delay="100"
        data-aos-offset="200"
        className="bg-white my-8 rounded-xl border-[1px] border-border p-5"
      >
        <div className="grid md:grid-cols-6 sm:grid-cols-2 grid-cols-1 gap-2">
          <div className="md:col-span-5 grid lg:grid-cols-4 items-center gap-6">
            <input
              type="text"
              placeholder='Search "daudi mburuge"'
              className="h-14 w-full text-sm text-main rounded-md bg-dry border border-border px-4"
            />
          </div>

          {/* export */}
          <Button
            label="Export"
            Icon={MdOutlineCloudDownload}
            onClick={() => {
              toast.error('Exporting is not available yet');
            }}
          />
        </div>

        <div className="mt-8 w-full overflow-x-scroll">
          <FacilityTable
            doctor={true}
            data={facilityData}
            functions={{
              preview: preview,
            }}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Facility;
