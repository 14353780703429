import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const DoctorLogin = () => {
  const navigate = useNavigate();

  const BaseUrl = "https://avijobackend-production.up.railway.app";

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginWithOtp, setLoginWithOtp] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    console.log('value:', e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };


  const handleSubmit = () => {
    if (email === "it@avijo.in" && password === "123456789") {
      navigate('/dashboard');
    }
  }

  const handleLogin = async (e) => {

    e.preventDefault();
    setLoading(true);


    const payload = loginWithOtp
      ? { emailOrMobile: email }
      : { emailOrMobile: email, password };

    try {
      const response = await axios.post(`${BaseUrl}/doctor/doctorlogin`, payload);
      const response2 = await fetch(`${BaseUrl}/doctor/getAllDoctorProfile`);
      const json = await response2.json();
      const docId = await json.data.find(item => item.emailId === response.data.data.emailId)
      console.log('Response:', response, docId._id);
      if (response.status === 200) {

        await navigate(`/doctors/preview/${docId._id}`);
        console.log('id:', response.data.data._id, response.data.data.emailId);
        localStorage.setItem('email', response.data.data.emailId);
        localStorage.setItem('id', response.data.data._id);
      } else {
        alert('error login...');
      }
    } catch (error) {
      console.error("Login Failed:", error);
      if (error.response.data.message === 'User not found') {
        // navigation.navigate("Register");
      }
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="rounded border">
      {/* <div className="bg-color"> */}
      <div className="flex flex-row items-end w-full pl-[5%] pt-[2%] pb-[2%] bg-white">
        <h1 className="text-[36px] text-black font-[600]" >avijo</h1><h6 className="text-[20px] font-[500] text-[#12CDB7] italic">Alpha</h6>
      </div>
      {/* </div> */}
      <div className="row align-content-center">
        <div className="col-md-6 doctor-left">
          <h4>
            Keep track of your patient's health with this
            <br /> powerful app that makes it easy to communicate with them.
          </h4>
          <ul className="custom-list-style">
            <li>Create professional profile</li>
            <li>Set available timings</li>
            <li>Accept/Reject appointment</li>
            <li>Video consultation</li>
            <li>Real-time chat with patients</li>
            <li>Read patient reviews</li>
            <li>Check customer reviews</li>
            <li>Easy login with OTP verification</li>
            <li>Multi-lingual including RTL</li>
          </ul>
        </div>
        <div className="col-md-6">
          <form
            className="width-75 border rounded form-doctor-right"
            onSubmit={
              handleSubmit
            }
          >
            <div>
              <div className="d-flex flex-column">
                <label>Mobile Number / HFR ID</label>
                <input
                  type="text"
                  placeholder="Mobile Number / HFR ID"
                  value={email}
                  onChange={handleEmail}
                />
              </div>
              <div className="d-flex flex-column">
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={handlePassword}
                />
              </div>

              <div>
                <div className="d-flex justify-content-between mt-3">
                  <div>
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={(e) => setRememberMe(e.target.checked)}
                    />
                    <label>Remember me for 30 days</label>
                  </div>
                  <div>
                    <Link to="/">Forgot password?</Link>
                  </div>
                </div>
                <div>
                  <div>
                    <input
                      type="checkbox"
                      checked={loginWithOtp}
                      onChange={(e) => setLoginWithOtp(e.target.checked)}
                    />
                    <label>Login with OTP instead of password.</label>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit" className="text-white">
              {loading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-white"
                    role="status"
                    aria-hidden="true"
                  ></span>
                </>
              ) : (
                "Login"
              )}
            </button>
          </form>
        </div>
      </div>
    </div >
  );
};

export default DoctorLogin;
