import React from 'react';
import Uploder from '../Uploader';
import { sortsDatas } from '../Datas';
import { Button, DatePickerComp, Input, Select } from '../Form';
import { BiChevronDown } from 'react-icons/bi';
import { toast } from 'react-hot-toast';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { RiDeleteBin5Line } from 'react-icons/ri';
import DatePicker from 'react-datepicker';

function PersonalInfo({ titles }) {
  const [title, setTitle] = React.useState(sortsDatas.title[0]);
  const [date, setDate] = React.useState(new Date());
  const [gender, setGender] = React.useState(sortsDatas.genderFilter[0]);
  return (
    <>
      <div className="w-[60%] bg-dry group flex flex-row items-center justify-between border border-border p-4 mt-3 rounded-lg focus-within:border focus-within:border-[#0095D9]">
          <img src={require('../../Assets/images/search.png')} className='h-6 w-6 mr-2' />
        <input
          type="text"
          placeholder='Search'
          className="w-full bg-transparent text-sm font-md focus:outline-none text-black"
        />
      </div>
      <div className="grid md:grid-cols-2  gap-4 items-end mt-4">
        {/* uploader */}
        {/* <div className="flex gap-3 flex-col w-full col-span-6">
        <p className="text-sm">Profile Image</p>
        <Uploder />
      </div> */}
        {/* select  */}
        {titles && (
          <div className="flex w-full flex-col gap-3">
            <p className="text-black text-sm">Title</p>
            <Select
              selectedPerson={title}
              setSelectedPerson={setTitle}
              datas={sortsDatas.title}
            >
              <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-[#0095D9]">
                {title?.name} <BiChevronDown className="text-xl" />
              </div>
            </Select>
          </div>
        )}

        {/* fullName */}
        <div className="text-sm w-[90%] mt-3">
          <div className='flex flex-row items-center w-full justify-between'>
            <label className={'text-black text-sm'}>UHI ID</label>
            <div className='bg-[#0095D9] p-2 rounded-sm'>
              <span className={'text-white text-sm'}>Generate</span>
            </div>
          </div>
          <div className="group flex flex-row items-center justify-between border border-border p-3 mt-3 rounded-lg focus-within:border focus-within:border-[#0095D9]">
            <input
              type="text"
              placeholder='Enter Health Id'
              className="w-full bg-transparent text-sm font-light focus:outline-none"
            />
            <div className='p-1 flex flex-row items-center rounded-full border border-border pl-4 pr-4 border-[#0095D9]'>
              <span className={'text- text-[#0095D9] mr-2'}>Get</span>
              <img src={require('../../Assets/images/restart.png')} className='h-4 w-4' />
            </div>
          </div>
        </div>
        <Input label="Full Name" placeholder={'Full Name'} color={true} type="text" />
        {/* phone */}
        <Input label="Phone Number" placeholder={"Phone Number"} color={true} type="number" />
        {/* email */}
        <Input label="Email" placeholder={"Email"} color={true} type="email" />
        {!titles && (
          <>
            {/* gender */}
            <div className="flex w-[90%] flex-col gap-3 mt-3">
              <p className="text-black text-sm">Gender</p>
              <Select
                selectedPerson={gender}
                setSelectedPerson={setGender}
                datas={sortsDatas.genderFilter}
              >
                <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-[#0095D9]">
                  {gender?.name} <BiChevronDown className="text-xl" />
                </div>
              </Select>
            </div>
            {/* emergancy contact */}
            <Input label="Emergency Cotact" placeholder={"Emergency Cotact"} color={true} type="number" />
            {/* date */}
            <div className="text-sm w-[90%] flex flex-col mt-3">
              <label
                className='text-black text-sm'
              >
                Date of Birth
              </label>
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date)}
                className="w-full bg-transparent text-sm mt-3 p-4 border border-border font-light rounded-lg focus:border focus:border-[#0095D9]"
              />
            </div>
            {/* address */}
            <Input label="Address" placeholder={"Address"} color={true} type="text" />
          </>
        )}
        {/* submit */}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 w-full mt-6">
        <Button
          label={'Cancel'}
          backgroundColor={"#B8B8B8"}
          // Icon={RiDeleteBin5Line}
          onClick={() => {
            toast.error('This feature is not available yet');
          }}
        />
        <Button
          label={'Invite'}
          // Icon={HiOutlineCheckCircle}
          onClick={() => {
            toast.error('This feature is not available yet');
          }}
        />
      </div>
    </>
  );
}

export default PersonalInfo;
