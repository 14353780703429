import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { Button, Input, Select } from '../Form';
import { BiChevronDown } from 'react-icons/bi';
import { facilityData, sortsDatas } from '../Datas';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { toast } from 'react-hot-toast';
import Access from '../Access';
import Uploader from '../Uploader';

function AddFacilityModal({ closeModal, isOpen, doctor, datas }) {
    const [instraction, setInstraction] = useState(facilityData.type[0]);
    const [instraction2, setInstraction2] = useState(facilityData.invitation[0]);
    const [access, setAccess] = useState({});

    useEffect(()=>{
        console.log('instruction2:', instraction2);
    },[])

    const onSubmit = () => {
        toast.error('This feature is not available yet');
    };

    return (
        <Modal
            closeModal={closeModal}
            isOpen={isOpen}
            title='Add Facility'
            width={'max-w-3xl'}
        >
            <div className="flex flex-row p-1 h-[45px] bg-[#F8F9FA] items-center justify-center rounded-[5px] w-[70%] mt-8 mb-6">
                <img src={require('../../Assets/images/search.png')} className="text-[#CDCED0] h-[16px] w-[16px] ml-2 mr-2" />
                <input
                    className="w-full text-start text-[14px] bg-[#F8F9FA]"
                    type="text"
                    placeholder="Search"
                />
            </div>
            <div className="grid sm:grid-cols-2 gap-4 w-full mb-4">
                <div className="flex w-full flex-col gap-3">
                    <p className="text-black text-sm">Invitation for</p>
                    <Select
                        selectedPerson={instraction2}
                        setSelectedPerson={setInstraction2}
                        datas={facilityData.invitation}
                    >
                        <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
                            {instraction2.name} <BiChevronDown className="text-xl" />
                        </div>
                    </Select>
                </div>
                <div className="flex w-full flex-col gap-3">
                    <p className="text-black text-sm">Facility type</p>
                    <Select
                        selectedPerson={instraction}
                        setSelectedPerson={setInstraction}
                        datas={facilityData.type}
                    >
                        <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
                            {instraction.name} <BiChevronDown className="text-xl" />
                        </div>
                    </Select>
                </div>
            </div>
            <div className="flex-colo gap-6">
               {instraction2.name == 'Partnership' && (<div className="grid sm:grid-cols-2 gap-4 w-full">
                    <Input label="Facility name" color={true} placeholder="Enter facility name" />
                    <Input label="Facility ID" color={true} placeholder="Enter facility I'd" />
                </div>)}

                <div className="grid sm:grid-cols-2 gap-4 w-full">
                    <Input label="Mobile number" placeholder="Enter mobile no." color={true} />
                    <Input label="Email" placeholder="Enter your Email" color={true} />
                </div>

                {/* buttones */}
                <div className="grid sm:grid-cols-2 gap-4 w-full">
                    <button
                        onClick={closeModal}
                        className="bg-[#B8B8B8] text-[white] text-sm p-4 rounded-lg font-light"
                    >
                        Cancel
                    </button>
                    <Button label="Invite" onClick={onSubmit} />
                </div>
            </div>
        </Modal>
    );
}

export default AddFacilityModal;
