import React, { useState } from 'react';
import Modal from './Modal';
import { Button, Input, Select } from '../Form';
import { BiChevronDown } from 'react-icons/bi';
import { sortsDatas } from '../Datas';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { toast } from 'react-hot-toast';
import Access from '../Access';
import Uploader from '../Uploader';

function AddDoctorModal({ closeModal, isOpen, doctor, datas }) {
  const [instraction, setInstraction] = useState(sortsDatas.title[0]);
  const [access, setAccess] = useState({});
  const [gender, setGender] = React.useState(sortsDatas.genderFilter[0]);

  const onSubmit = () => {
    toast.error('This feature is not available yet');
  };

  return (
    <Modal
      closeModal={closeModal}
      isOpen={isOpen}
      title={doctor ? 'Add Professionals' : datas?.id ? 'Edit Stuff' : 'Add Stuff'}
      width={'max-w-3xl'}>
      {/* <div className="flex gap-3 flex-col col-span-6 mb-6">
        <p className="text-sm">Profile Image</p>
        <Uploader />
      </div> */}
      <div className="flex-colo gap-6">
        <div className="w-[70%] bg-dry group flex flex-row items-center justify-between border border-border p-3 mt-3 rounded-lg focus-within:border focus-within:border-[#0095D9] self-start">
          <img src={require('../../Assets/images/search.png')} className='h-6 w-6 mr-2' />
          <input
            type="text"
            placeholder='Search'
            className="w-full bg-transparent text-sm font-md focus:outline-none text-black"

          />
        </div>
        <div className="grid sm:grid-cols-2 gap-4 w-full items-end">
          <div className="text-sm w-[90%] mt-3">
            <div className='flex flex-row items-center w-full justify-between'>
              <label className={'text-black text-sm'}>HPR ID</label>
              <div className='bg-[#0095D9] p-2 rounded-sm'>
                <span className={'text-white text-sm'}>Generate</span>
              </div>
            </div>
            <div className="group flex flex-row items-center justify-between border border-border p-3 mt-3 rounded-lg focus-within:border focus-within:border-[#0095D9]">
              <input
                type="text"
                placeholder='Enter Health Id'
                className="w-full bg-transparent text-sm font-light focus:outline-none"
              />
              <div className='p-1 flex flex-row items-center rounded-full border border-border pl-4 pr-4 border-[#0095D9]'>
                <span className={'text- text-[#0095D9] mr-2'}>Get</span>
                <img src={require('../../Assets/images/restart.png')} className='h-4 w-4' />
              </div>
            </div>
          </div>
          <div className="flex w-[90%] flex-col gap-3 mt-3">
            <p className="text-black text-sm">Title</p>
            <Select
              selectedPerson={instraction}
              setSelectedPerson={setInstraction}
              datas={sortsDatas.title}
            >
              <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-subMain">
                {instraction.name}
                <BiChevronDown className="text-xl" />
              </div>
            </Select>
          </div>
          <Input label="Full Name" color={true} placeholder="John Doe" />
          <Input label="Specialization" placeholder={"Specialization"} color={true} />
          <Input label="Phone Number" placeholder={"Phone Number"} color={true} />
          <Input label="Email" placeholder={"Email"} color={true} />

          <div className="flex w-[90%] flex-col gap-3 mt-3">
            <p className="text-black text-sm">Gender</p>
            <Select
              selectedPerson={gender}
              setSelectedPerson={setGender}
              datas={sortsDatas.genderFilter}
            >
              <div className="w-full flex-btn text-textGray text-sm p-4 border border-border font-light rounded-lg focus:border focus:border-[#0095D9]">
                {gender?.name} <BiChevronDown className="text-xl" />
              </div>
            </Select>
          </div>
          {/* password */}
          {/* <Input label="Password" color={true} />
          <Input label="HP id" color={true} /> */}
        </div>
        {/* table access */}
        {/* <div className="w-full">
          <Access setAccess={setAccess} />
        </div> */}

        {/* buttones */}
        <div className="grid sm:grid-cols-2 gap-4 w-full">
          <button
            onClick={closeModal}
            className="bg-[#B8B8B8] text-white text-sm p-4 rounded-lg font-light"
          >
            Cancel
          </button>
          <Button label="Invite" Icon={HiOutlineCheckCircle} onClick={onSubmit} />
        </div>
      </div>
    </Modal>
  );
}

export default AddDoctorModal;
