import React, { useState } from "react";
import { MdLogout, MdOutlineDriveFolderUpload, MdVerified } from "react-icons/md";
import { FaChevronDown } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { FaLandmarkDome, FaLocationDot, FaLocationPin, FaLocationPinLock } from "react-icons/fa6";
import CollapsibleDropdown from "../components/CollapsibleDropdown";


export default function HfrRegister() {

    const [tab, setTab] = useState(0);

    const [trackingId, setTrackingId] = useState("");
    const [facilityName, setFacilityName] = useState("Eye Care Center");
    const [ownershipCode, setOwnershipCode] = useState("G");
    const [ownershipSubTypeCode, setOwnershipSubTypeCode] = useState("");
    const [ownershipSubTypeCode2, setOwnershipSubTypeCode2] = useState("MOHF");

    const [loading, setLoading] = useState(false);

    const [facilityAddressDetails, setFacilityAddressDetails] = useState({
        country: "India",
        stateLGDCode: "35",
        districtLGDCode: "",
        subDistrictLGDCode: "4023",
        facilityRegion: "",
        villageCityTownLGDCode: "534829",
        addressLine1: "SushrutMultispeciality Clinic",
        addressLine2: "Gandhi chowk,opp.Dr.Kazi Hospital",
        pincode: "441304",
        latitude: "2",
        longitude: "7",
    });

    const [facilityContactInformation, setFacilityContactInformation] = useState({
        facilityContactNumber: "",
        facilityEmailId: "",
        facilityLandlineNumber: "",
        facilityStdCode: "",
        websiteLink: "",
    });

    const [systemOfMedicineCode, setSystemOfMedicineCode] = useState("M");
    const [facilityTypeCode, setFacilityTypeCode] = useState("5");
    const [facilitySubType, setFacilitySubType] = useState("");
    const [specialityTypeCode, setSpecialityTypeCode] = useState("");
    const [typeOfServiceCode, setTypeOfServiceCode] = useState("OPD");
    const [facilityOperationalStatus, setFacilityOperationalStatus] = useState("");

    const [facilityUploads, setFacilityUploads] = useState({
        facilityBoardPhoto: {
            name: "",
            value: "",
        },
        facilityBuildingPhoto: {
            name: "",
            value: "",
        },
    });

    const [facilityAddressProof, setFacilityAddressProof] = useState([
        {
            addressProofType: "",
            addressProofAttachment: {
                name: "",
                value: "",
            },
        },
    ]);

    const [timingsOfFacility, setTimingsOfFacility] = useState([
        {
            workingDays: "Tue",
            openingHours: "1:00 AM - 2:00 pm",
        },
        {
            workingDays: "Wed",
            openingHours: "1:00 AM - 2:00 pm",
        },
    ]);

    const [abdmCompliantSoftware, setAbdmCompliantSoftware] = useState([
        {
            existingSoftwares: ["12121221", "12121111"],
            anyOther: "",
        },
    ]);
    const data = {
        trackingId: "",
        facilityInformation: {
            facilityName: "Eye Care Center",
            ownershipCode: "G",
            ownershipSubTypeCode: "",
            ownershipSubTypeCode2: "MOHF",
            facilityAddressDetails: {
                facilityAddressDetails
            },
            facilityContactInformation: {
                facilityContactInformation
            },
            systemOfMedicineCode: "M",
            facilityTypeCode: "5",
            facilitySubType: "",
            specialityTypeCode: "",
            typeOfServiceCode: "OPD",
            facilityOperationalStatus: "",
            facilityUploads: {
                facilityBoardPhoto: {
                    name: "",
                    value: "",
                },
                facilityBuildingPhoto: {
                    name: "",
                    value: "",
                },
            },
            facilityAddressProof: [
                {
                    addressProofType: "",
                    addressProofAttachment: {
                        name: "",
                        value: "",
                    },
                },
            ],
            timingsOfFacility: [
                {
                    workingDays: "Tue",
                    openingHours: "1:00 AM - 2:00 pm",
                },
                {
                    workingDays: "Wed",
                    openingHours: "1:00 AM - 2:00 pm",
                },
            ],
            abdmCompliantSoftware: [
                {
                    existingSoftwares: ["12121221", "12121111"],
                    anyOther: "",
                },
            ],
        },
    }

    const handleMobileNumber = (e) => {
        setFacilityContactInformation({
            ...facilityContactInformation,
            facilityContactNumber: e.target.value,
        })
    };

    const handleEmail = (e) => {
        setFacilityContactInformation({
            ...facilityContactInformation,
            facilityEmailId: e.target.value,
        })
    };

    const handleFacilityName = (e) => {
        setFacilityName(e.target.value);
    }

    const handleCountry = (e) => {
        setFacilityAddressDetails({
            ...facilityAddressDetails,
            country: e.target.value
        })
    };

    const handleState = (e) => {
        setFacilityAddressDetails({
            ...facilityAddressDetails,
            stateLGDCode: e.target.value
        })
    };
    const handleRegion = (e) => {
        setFacilityAddressDetails({
            ...facilityAddressDetails,
            facilityRegion: e.target.value
        })
    };

    const handleCity = (e) => {
        setFacilityAddressDetails({
            ...facilityAddressDetails,
            villageCityTownLGDCode: e.target.value
        })
    };

    const handleAddress1 = (e) => {
        setFacilityAddressDetails({
            ...facilityAddressDetails,
            addressLine1: e.target.value
        })
    };
    const handleAddress2 = (e) => {
        setFacilityAddressDetails({
            ...facilityAddressDetails,
            addressLine2: e.target.value
        })
    };

    const handlePincode = (e) => {
        setFacilityAddressDetails({
            ...facilityAddressDetails,
            pincode: e.target.value
        })
    };

    const handleLandlineNumber = (e) => {
        e.preventDefault()
        setFacilityContactInformation({
            ...facilityContactInformation,
            facilityLandlineNumber: e.target.value,
        })
    };
    const handleWebsite = (e) => {
        setFacilityContactInformation({
            ...facilityContactInformation,
            websiteLink: e.target.value,
        })
    };

    const handleDistrict = (option) => {
        setFacilityAddressDetails({
            ...facilityAddressDetails,
            districtLGDCode: option
        })
    };

    const handleSubDistrict = (option) => {
        setFacilityAddressDetails({
            ...facilityAddressDetails,
            subDistrictLGDCode: option
        })
    };


    const postData = async () => {
        try {
            setLoading(true);
            const response = await fetch(`https://facilitysbx.abdm.gov.in/FacilityManagement/v1.5/facility/basic-information`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json', // or any other type depending on your needs
                },
                body: JSON.stringify(data), // converting JS object to JSON string
            });

            // Check if the response status is OK (200-299)
            if (response.ok) {
                const responseData = await response.json(); // Parse JSON response
                console.log('Success:', responseData);
                alert('success...');
            } else {
                console.log('Error:', response.statusText);
                alert('error posting...');
            }
        } catch (error) {
            console.error('Request failed:', error);
        } finally {
            setLoading(false);
        }
    };


    const Details = () => {

        return (
            <div className="flex flex-col w-full pt-4 mb-2 bg-white mt-4 items-center pb-4">
                <div className="flex flex-row items-center justify-center w-full">
                    <div className="h-[15px] w-[15px] rounded-full bg-[#0095D9] items-center justify-center flex flex-col">
                        <TiTick className="text-[14px] text-white self-center text-regular" />
                    </div>
                    <div className="h-1 w-[15%] bg-[#0095D9]" />
                    <div className="h-[15px] w-[15px] rounded-full bg-[#0095D9] flex flex-col items-center justify-center">
                        <span className="text-white text-[10px] text-semibold">2</span>
                    </div>
                    <div className="h-1 w-[20%] bg-gray-200" />
                    <div className="h-[15px] w-[15px] rounded-full bg-gray-200 flex flex-col items-center justify-center">
                        <span className="text-gray-500 text-[10px] text-semibold">3</span>
                    </div>
                    <div className="h-1 w-[15%] bg-gray-200" />
                    <div className="h-[15px] w-[15px] rounded-full bg-gray-200 flex flex-col items-center justify-center">
                        <span className="text-gray-500 text-[10px] text-semibold">4</span>
                    </div>
                    <div className="h-1 w-[15%] bg-gray-200" />
                </div>
                <div className="flex flex-row items-center justify-between w-[65%] mt-2 mr-[10%] ">
                    <span className="text-[10px] text-[#0095D9] text-semibold w-[25%]">Personal Details</span>
                    <span className="text-[10px] text-gray-500 w-[25%] ">Qualification & Registration Details</span>
                    <span className="text-[10px] text-gray-500 w-[25%] text-center ">Work Details</span>
                    <span className="text-[10px] text-gray-500 w-[25%] text-center ">Preview Profile</span>
                </div>
                <div className="w-full items-start flex flex-col mt-[5%]">
                    <div className="bg-[#0095D9] ml-[5%] md:w-[30%] w-[70%] h-[40px] justify-center flex flex-col rounded-md">
                        <span className="text-[18px] text-white text-semiBold ml-4">System of Medicine</span>
                    </div>
                </div>
                <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                        <span className="text-[12px] text-gray-500 font-semibold mt-2">Category</span>
                        <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                            <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5">Doctor</span>
                            <div className="w-[10%]">
                                <FaChevronDown className="mt-3 text-[15px]" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                        <span className="text-[12px] text-gray-500 font-semibold mt-2">System of medicine</span>
                        <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                            <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5">Modern Medicine</span>
                            <div className="w-[10%]">
                                <FaChevronDown className="mt-3 text-[15px]" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                    </div>
                </div>
                <div className="w-full items-start flex flex-col mt-[5%]">
                    <div className="bg-[#0095D9] ml-[5%] md:w-[30%] w-[70%] h-[40px] justify-center flex flex-col rounded-md">
                        <span className="text-[18px] text-white text-semiBold ml-4">Registration Details</span>
                    </div>
                </div>
                <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                        <span className="text-[12px] text-gray-500 font-semibold mt-2">Register with council</span>
                        <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                            <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5">Doctor</span>
                            <div className="w-[10%]">
                                <FaChevronDown className="mt-3 text-[15px]" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column md:w-[30%] w-[90%] mt-2">
                        <span className="text-[12px] text-gray-500 font-semibold">Registration Number</span>
                        <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                            <input
                                type="text"
                                // name="fullName"
                                // value={formData.fullName}
                                // onChange={handleInputChange}
                                // required
                                className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                            />
                            {/* <div className="w-[10%]">
                                <MdVerified className="text-[#12DB00] self-center" />
                            </div> */}
                        </div>
                    </div>
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                        <span className="text-[12px] text-gray-500 font-semibold mt-2">Date of first Registration</span>
                        <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                            <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5">DD/MM/YYYY</span>
                            <div className="w-[10%]">
                                <FaChevronDown className="mt-3 text-[15px]" />
                            </div>
                        </div>
                    </div>
                </div>
                <span className="text-[12px] text-gray-500 font-semibold mt-4 w-[90%]">Is the registration permanent or renewal?</span>
                <div className="flex flex-row items-center w-[90%]">
                    <div className="d-flex flex-row items-center mt-2">
                        {" "}
                        <input
                            type="radio"
                            name="gender"
                            className="input-radio mt-0 h-[15px] w-[15px]"
                            value="Male"
                        // checked={formData.gender === "Male"}
                        // onChange={handleChange}
                        />{" "}
                        <span className="text-[12px] font-semibold text-gray-400">Renewal</span>
                    </div>
                    <div className="d-flex flex-row items-center mt-2 ml-4">
                        {" "}
                        <input
                            type="radio"
                            name="gender"
                            className="input-radio mt-0 h-[15px] w-[15px]"
                            value="Male"
                        // checked={formData.gender === "Male"}
                        // onChange={handleChange}
                        />{" "}
                        <span className="text-[12px] font-semibold text-gray-400">Permanent</span>
                    </div>
                </div>
                <span className="text-[12px] text-gray-500 font-semibold mt-4 w-[90%]">Registration Certificate Attachment</span>
                <div className="w-[90%] items-start mt-4">
                    <div className="md:w-[60%] w-[90%] h-[45px] border border-gray-500 rounded-md flex flex-row items-center justify-center">
                        <MdOutlineDriveFolderUpload className="text-xl text-blue-500" />
                        <span className="text-[12px] font-semibold text-gray-400 pl-2">Drag and Drop File or Browse</span>
                    </div>
                </div>
                <span className="text-[12px] text-gray-500 font-semibold mt-4 w-[90%]">Is your name in registration certificate the same as your name in Aadhar?</span>
                <div className="flex flex-row items-center w-[90%]">
                    <div className="d-flex flex-row items-center mt-2">
                        {" "}
                        <input
                            type="radio"
                            name="gender"
                            className="input-radio mt-0 h-[15px] w-[15px]"
                            value="Male"
                        // checked={formData.gender === "Male"}
                        // onChange={handleChange}
                        />{" "}
                        <span className="text-[12px] font-semibold text-gray-400">Yes</span>
                    </div>
                    <div className="d-flex flex-row items-center mt-2 ml-4">
                        {" "}
                        <input
                            type="radio"
                            name="gender"
                            className="input-radio mt-0 h-[15px] w-[15px]"
                            value="Male"
                        // checked={formData.gender === "Male"}
                        // onChange={handleChange}
                        />{" "}
                        <span className="text-[12px] font-semibold text-gray-400">No</span>
                    </div>
                </div>
                <div className="w-full items-start flex flex-col mt-[2%]">
                    <div className="bg-[#0095D9] ml-[5%] md:w-[30%] w-[70%] h-[40px] justify-center flex flex-col rounded-md">
                        <span className="text-[18px] text-white text-semiBold ml-4">Qualification Details</span>
                    </div>
                </div>
                <div className="flex flex-row items-center w-[90%] mt-2">
                    <div className="d-flex flex-row items-center mt-2">
                        {" "}
                        <input
                            type="radio"
                            name="gender"
                            className="input-radio mt-0 h-[15px] w-[15px]"
                            value="Male"
                        // checked={formData.gender === "Male"}
                        // onChange={handleChange}
                        />{" "}
                        <span className="text-[12px] font-semibold text-gray-400">India</span>
                    </div>
                    <div className="d-flex flex-row items-center mt-2 ml-4">
                        {" "}
                        <input
                            type="radio"
                            name="gender"
                            className="input-radio mt-0 h-[15px] w-[15px]"
                            value="Male"
                        // checked={formData.gender === "Male"}
                        // onChange={handleChange}
                        />{" "}
                        <span className="text-[12px] font-semibold text-gray-400">Other</span>
                    </div>
                </div>
                <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                    <div className="d-flex flex-column md:w-[68%] w-[90%]">
                        <span className="text-[12px] text-gray-500 font-semibold mt-2">Name of Degree/Diploma</span>
                        <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                            <span className="text-[14px] text-black h-40 w-[95%] pl-2 text-start pt-2.5"></span>
                            <div className="w-[5%]">
                                <FaChevronDown className="mt-3 text-[15px]" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                        <span className="text-[12px] text-gray-500 font-semibold mt-2">Country</span>
                        <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                            <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5">India</span>
                            <div className="w-[10%]">
                                <FaChevronDown className="mt-3 text-[15px]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                        <span className="text-[12px] text-gray-500 font-semibold mt-2">State</span>
                        <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                            <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5"></span>
                            <div className="w-[10%]">
                                <FaChevronDown className="mt-3 text-[15px]" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                        <span className="text-[12px] text-gray-500 font-semibold mt-2">College</span>
                        <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                            <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5"></span>
                            <div className="w-[10%]">
                                <FaChevronDown className="mt-3 text-[15px]" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                        <span className="text-[12px] text-gray-500 font-semibold mt-2">University</span>
                        <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                            <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5">University</span>
                            <div className="w-[10%]">
                                <FaChevronDown className="mt-3 text-[15px]" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                        <span className="text-[12px] text-gray-500 font-semibold mt-2">Passing month</span>
                        <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                            <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5"></span>
                            <div className="w-[10%]">
                                <FaChevronDown className="mt-3 text-[15px]" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                        <span className="text-[12px] text-gray-500 font-semibold mt-2">Passing year</span>
                        <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                            <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5"></span>
                            <div className="w-[10%]">
                                <FaChevronDown className="mt-3 text-[15px]" />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column md:w-[30%] w-[90%]">
                    </div>
                </div>
                <span className="text-[12px] text-gray-500 font-semibold mt-4 w-[90%]">Upload Degree/Diploma</span>
                <div className="w-[90%] items-start mt-4">
                    <div className="md:w-[60%] w-[90%] h-[45px] border border-gray-500 rounded-md flex flex-row items-center justify-center">
                        <MdOutlineDriveFolderUpload className="text-xl text-blue-500" />
                        <span className="text-[12px] font-semibold text-gray-400 pl-2">Drag and Drop File or Browse</span>
                    </div>
                </div>
                <span className="text-[12px] text-gray-500 font-semibold mt-4 w-[90%]">Is your name in the Degree same as your name in Aadhar?</span>
                <div className="flex flex-row items-center w-[90%]">
                    <div className="d-flex flex-row items-center mt-2">
                        {" "}
                        <input
                            type="radio"
                            name="gender"
                            className="input-radio mt-0 h-[15px] w-[15px]"
                            value="Male"
                        // checked={formData.gender === "Male"}
                        // onChange={handleChange}
                        />{" "}
                        <span className="text-[12px] font-semibold text-gray-400">Yes</span>
                    </div>
                    <div className="d-flex flex-row items-center mt-2 ml-4">
                        {" "}
                        <input
                            type="radio"
                            name="gender"
                            className="input-radio mt-0 h-[15px] w-[15px]"
                            value="Male"
                        // checked={formData.gender === "Male"}
                        // onChange={handleChange}
                        />{" "}
                        <span className="text-[12px] font-semibold text-gray-400">No</span>
                    </div>
                </div>
                <div className="w-[90%] items-start mt-4">
                    <div className="md:w-[60%] w-[90%] h-[45px] border border-gray-500 rounded-md flex flex-row items-center justify-center">
                        <MdOutlineDriveFolderUpload className="text-xl text-blue-500" />
                        <span className="text-[12px] font-semibold text-gray-400 pl-2">Drag and Drop File or Browse</span>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-between w-[90%] mt-8 pb-4">
                    <button onClick={() => setTab(1)} className="flex flex-row items-center w-[100px] h-[40px] rounded-md justify-center bg-[#B8B8B8]">
                        <span className="text-sm text-[white]">Back</span>
                    </button>
                    <button onClick={() => setTab(0)} className="flex flex-row items-center w-[100px] h-[40px] rounded-md justify-center bg-[#0095D9] ml-4">
                        <span className="text-sm text-[white]">Save as Draft</span>
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className="flex flex-col w-full items-center bg-gray-100">
            {tab !== 0 && < div className="flex flex-row item-center w-full pt-4 pb-2 bg-white mt-4">
                <div className="h-[70px] w-[70px] bg-gray-200 rounded-full border border-[grey] ml-4"></div>
                <div className="flex flex-col items-start ml-5 ">
                    <span className="text-gray-500 font-semibold text-[14px]">Jane Cooper</span>
                    <span className="text-gray-400 text-[14px]">Gender: Male</span>
                    <span className="text-gray-400 text-[14px]">Date of Birth: 12-04-2001</span>
                </div>
            </div>}
            {
                tab === 0 && <Registration
                    facilityName={facilityName}
                    handleFacilityName={handleFacilityName}
                    country={facilityAddressDetails.country}
                    handleCountry={handleCountry}
                    stateLGDCode={facilityAddressDetails.stateLGDCode}
                    handleState={handleState}
                    // districtLGDCode={facilityAddressDetails.districtLGDCode}
                    // handleDistrict={handleDistrict}
                    // subDistrictLGDCode={facilityAddressDetails.subDistrictLGDCode}
                    // handleSubDistrict={handleSubDistrict}
                    facilityRegion={facilityAddressDetails.facilityRegion}
                    handleRegion={handleRegion}
                    villageCityTownLGDCode={facilityAddressDetails.villageCityTownLGDCode}
                    handleCity={handleCity}
                    addressLine1={facilityAddressDetails.addressLine1}
                    handleAddress1={handleAddress1}
                    addressLine2={facilityAddressDetails.addressLine2}
                    handleAddress2={handleAddress2}
                    pincode={facilityAddressDetails.pincode}
                    handlePincode={handlePincode}
                    facilityLandlineNumber={facilityContactInformation.facilityLandlineNumber}
                    handleLandlineNumber={handleLandlineNumber}
                    facilityContactNumber={facilityContactInformation.facilityContactNumber}
                    handleMobileNumber={handleMobileNumber}
                    facilityEmailId={facilityContactInformation.facilityEmailId}
                    handleEmail={handleEmail}
                    websiteLink={facilityContactInformation.websiteLink}
                    handleWebsite={handleWebsite}
                    loading={loading}
                    onSave={postData}
                />
            }
            {
                tab === 1 && <PersonalDetail
                    facilityContactNumber={facilityContactInformation.facilityContactNumber}
                    handleMobileNumber={handleMobileNumber}
                    facilityEmailId={facilityContactInformation.facilityEmailId}
                    handleEmail={handleEmail}
                    districtLGDCode={facilityAddressDetails.districtLGDCode}
                    handleDistrict={handleDistrict}
                    subDistrictLGDCode={facilityAddressDetails.subDistrictLGDCode}
                    handleSubDistrict={handleSubDistrict}
                    onSubmit={() => setTab(1)}
                />
            }
            {tab === 2 && <Details />}
            {
                tab === 0 && <div className="w-full items-center justify-center flex flex-col mt-8 pb-8">
                    <h2 className="text-black">Need Help?</h2>
                    <span className="text-[10px] text-gray-500 font-semibold mt-2 text-center md:w-[25%] w-[90%]">If you have questions regarding healthcare Professional ID, Please go through our <span className="text-[#0095D9]">FAQS section</span></span>
                    <span className="text-[10px] text-gray-500 font-semibold mt-2 text-center md:w-[25%] w-[90%]">If you are not able to register or are facing other issues with registration, please contact us at <span className="text-[#0095D9]">avijo.in</span></span>
                    <span className="text-[10px] text-gray-500 font-semibold mt-2 text-center md:w-[25%] w-[90%]">Or call us at our toll free number - <span className="text-black">1800-11-4477 / 14477</span></span>
                    <button className="flex flex-row items-center w-[100px] h-[40px] rounded-md justify-center bg-[#0095D9] ml-4 mt-4">
                        <span className="text-sm text-[white] pl-2">Get Help</span>
                    </button>
                </div>
            }
        </div >
    )
}

// facilityContactInformation



const PersonalDetail = (props) => {

    return (
        <div className="flex flex-col w-full pt-4 mb-2 bg-white mt-4 items-center pb-4">
            <span className="text-[#0097DB] font-semibold text-[26px] w-[95%]">Registration From (Mobile verification is required)</span>
            <div className="flex md:flex-row flex-col md: justify-between w-[95%] items-center mt-4">
                <div className="d-flex flex-column md:w-[30%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Mobile Number</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            name="fullName"
                            placeholder="6478392982"
                            value={props.facilityContactNumber}
                            onChange={props.handleMobileNumber}
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-[90%] h-[40px]"
                        />
                        <div className="w-[10%]">
                            <MdVerified className="text-[#12DB00] self-center" />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[30%] w-[90%]">
                    <span className="text-[12px] text-gray-500 font-semibold mt-2">Email</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="email"
                            // name="fullName"
                            placeholder="example.com"
                            value={props.facilityEmailId}
                            onChange={props.handleEmail}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-[85%] h-[40px]"
                        />
                        <div className="w-[15%] items-center">
                            <span className="text-[14px] text-[#0097DB] mt-2">verify</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[30%] w-[90%]">
                    <span className="text-[12px] text-gray-500 font-semibold mt-2">Date of Birth</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            placeholder="00-00-0000"
                            // value={formData.fullName}
                            // onChange={handleInputChange}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-[90%] h-[40px]"
                        />
                        {/* <div className="w-[10%]">
                            <MdVerified className="text-[#12DB00] self-center" />
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[95%] items-center mt-4">
                <div className="flex-column md:w-[30%] w-[90%]">
                    <CollapsibleDropdown
                        label="District"
                        content={props.districtLGDCode}
                        options={["Option 1", "Option 2", "Option 3"]}
                        onSelect={props.handleDistrict}
                    />
                </div>
                <div className="flex-column md:w-[30%] w-[90%]">
                    <CollapsibleDropdown
                        label="Sub District"
                        content={props.subDistrictLGDCode}
                        options={["Option 1", "Option 2", "Option 3"]}
                        onSelect={props.handleSubDistrict}
                    />
                </div>
                <div className="flex-column md:w-[30%] w-[90%]">
                </div>
            </div>
            <div className="flex flex-col items-start w-[95%] mt-4">
                <h5 className="text-gray-500">Roles</h5>
                <div className="d-flex flex-row items-center">
                    {" "}
                    <input
                        type="radio"
                        name="gender"
                        className="input-radio mt-1 h-[15px] w-[15px]"
                        value="Male"
                    // checked={formData.gender === "Male"}
                    // onChange={handleChange}
                    />{" "}
                    <span className="text-[14px] font-semibold text-gray-400"> I am a Healthcare Professional</span>
                </div>
                <div className="d-flex flex-row items-center mt-2">
                    {" "}
                    <input
                        type="radio"
                        name="gender"
                        className="input-radio mt-1 h-[15px] w-[15px]"
                        value="Male"
                    // checked={formData.gender === "Male"}
                    // onChange={handleChange}
                    />{" "}
                    <span className="text-[14px] font-semibold text-gray-400">I am a Facility Manager / Administrator</span>
                </div>
                <div className="d-flex flex-row items-center mt-2">
                    {" "}
                    <input
                        type="radio"
                        name="gender"
                        className="input-radio mt-1 h-[15px] w-[15px]"
                        value="Male"
                    // checked={formData.gender === "Male"}
                    // onChange={handleChange}
                    />{" "}
                    <span className="text-[14px] font-semibold text-gray-400">I am a Healthcare Professional & Facility Manager</span>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[95%] items-center mt-4">
                <div className="d-flex flex-column md:w-[30%] w-[90%]">
                    <span className="text-[12px] text-gray-500 font-semibold">Category</span>
                    <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                        <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5">example</span>
                        <div className="w-[10%]">
                            <FaChevronDown className="mt-3 text-[15px]" />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[30%] w-[90%]">
                    <span className="text-[12px] text-gray-500 font-semibold mt-2">Sub Category</span>
                    <div className="w-full h-[50px] rounded-[10px] border border-gray-500 mt-2 w-full flex flex-row">
                        <span className="text-[14px] text-black h-40 w-[90%] pl-2 text-start pt-2.5">example</span>
                        <div className="w-[10%]">
                            <FaChevronDown className="mt-3 text-[15px]" />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[30%] w-[90%]">
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[95%] items-center mt-4">
                <div className="d-flex flex-column md:w-[30%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Healthcare Professional ID/Username</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            placeholder="@hpr.abdm"
                            // value={formData.fullName}
                            // onChange={handleInputChange}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                        {/* <div className="w-[10%]">
                            <MdVerified className="text-[#12DB00] self-center" />
                        </div> */}
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[30%] w-[90%]">
                    <span className="text-[12px] text-gray-500 font-semibold mt-2">Password</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="password"
                            // name="fullName"
                            placeholder="Password"
                            // value={formData.fullName}
                            // onChange={handleInputChange}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                        {/* <div className="w-[15%] items-center">
                            <span className="text-[14px] text-[#0097DB] mt-2">verify</span>
                        </div> */}
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[30%] w-[90%]">
                    <span className="text-[12px] text-gray-500 font-semibold mt-2">Confirm Password</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="password"
                            // name="fullName"
                            placeholder="Confirm Password"
                            // value={formData.fullName}
                            // onChange={handleInputChange}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
            </div>
            <div className="flex flex-row items-center justify-start w-[95%] mt-8 pb-4">
                <button className="flex flex-row items-center w-[40%] h-[50px] rounded-md justify-center bg-[#B8B8B8]">
                    <span className="text-lg text-[white] pl-2">Reset</span>
                </button>
                <button onClick={props.onSubmit} className="flex flex-row items-center w-[40%] h-[50px] rounded-md justify-center bg-[#0095D9] ml-4">
                    <span className="text-lg text-[white] pl-2">Submit</span>
                </button>
            </div>
        </div>
    )
}

const Registration = (props) => {

    return (
        <div className="flex flex-col w-full pt-4 mb-2 bg-white mt-0 items-center pb-4">
            < div className="flex flex-row items-center w-full bg-white mt-2 justify-between border-b-[10px] pb-2">
                < div className="flex flex-row items-center bg-white">
                    <div className="ml-4">
                        <h1 className="text-[#0097DB]">avijo</h1>
                    </div>
                    <div className="h-[20px] w-[1px] bg-[#0097DB] ml-4" />
                    <div className="flex flex-col ml-5 ">
                        <span className="text-[#0097DB] font-semibold text-[14px]">Welcome</span>
                    </div>
                </div>
                < div className="flex flex-row items-center bg-white">
                    <div className="flex flex-row items-center">
                        <img src={require('../Assets/images/notification.png')} className="h-[28px] w-[30px]" />
                        <FaChevronDown className="h-[15px] w-[15px] ml-2" />
                    </div>
                    <div className="flex flex-row items-center ml-4">
                        <MdLogout className="text-2xl text-[#0097DB]" />
                        <span className="text-gray-500 font-regular text-[12px]">Log Out</span>
                    </div>
                    <div className="flex flex-col items-center ml-4">
                        <div className="h-[40px] w-[40px] rounded-full border border-2 bg-gray-100"></div>
                        <span className="text-gray-500 text-[Gilroy-SemiBold] text-[10px] w-[100px] text-center">Health facility Registery</span>
                    </div>
                </div>
            </div>
            <div className="w-full items-start flex flex-col mt-[5%]">
                <div className="bg-white ml-[5%] md:w-[30%] w-[70%] h-[40px] justify-center flex flex-col rounded-md">
                    <span className="text-gray-500 text-[26px] text-bold">Facility Details</span>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Facility Name</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.facilityName}
                            onChange={props.handleFacilityName}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Country</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.country}
                            onChange={props.handleCountry}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">State/UT</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.stateLGDCode}
                            onChange={props.handleState}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">District</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.districtLGDCode}
                            onChange={props.handleDistrict}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Sub-District</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.subDistrictLGDCode}
                            onChange={props.handleSubDistrict}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Facility Region</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.facilityRegion}
                            onChange={props.handleRegion}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Village/City/town</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.villageCityTownLGDCode}
                            onChange={props.handleCity}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Address No.1</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.addressLine1}
                            onChange={props.handleAddress1}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Address No.2</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.addressLine2}
                            onChange={props.handleAddress2}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Pin Code</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.pincode}
                            onChange={props.handlePincode}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Landline Number</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.facilityLandlineNumber}
                            onChange={props.handleLandlineNumber}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Mobile Number (For Public Display)</span>
                    <div className="flex flex-row items-center w-full h-[50px]">
                        <div className="flex flex-row items-center border border-gray-500 mt-2 w-[80%] h-[50px] rounded-[10px] focus:border-[#0097DB]">
                            <input
                                type="text"
                                // name="fullName"
                                value={props.facilityContactNumber}
                                onChange={props.handleMobileNumber}
                                // required
                                className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                            />
                        </div>
                        <div className="bg-[#0095D9] rounded-lg w-[18%] h-[50px] ml-[2%] mt-2 items-center flex flex-col justify-center">
                            <span className="text-[14px] font-semibold text-white">Verify</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Facility Email (For Public Display)</span>
                    <div className="flex flex-row items-center w-full h-[50px]">
                        <div className="flex flex-row items-center border border-gray-500 mt-2 w-[80%] h-[50px] rounded-[10px] focus:border-[#0097DB]">
                            <input
                                type="text"
                                // name="fullName"
                                value={props.facilityEmailId}
                                onChange={props.handleEmail}
                                // required
                                className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                            />
                        </div>
                        <div className="bg-[#2CBA20] rounded-lg w-[18%] h-[50px] ml-[2%] mt-2 items-center flex flex-col justify-center">
                            <span className="text-[14px] font-semibold text-white">Verify</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Website (For Public Display)</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            value={props.websiteLink}
                            onChange={props.handleWebsite}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Geographic Location</span>
                    <div className="flex flex-row items-center w-full h-[50px]">
                        <div className="flex flex-row items-center border border-gray-500 mt-2 w-[85%] h-[50px] rounded-[10px] focus:border-[#0097DB]">
                            <input
                                type="text"
                                // name="fullName"
                                // value={formData.fullName}
                                // onChange={handleInputChange}
                                // required
                                className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                            />
                        </div>
                        <div className="bg-[#0095D9] rounded-lg w-[13%] h-[50px] ml-[2%] mt-2 items-center flex flex-col justify-center">
                            {/* <span className="text-[14px] font-semibold text-white"></span> */}
                            <FaLocationDot className="text-lg text-white" />
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column md:w-[49%] w-[90%] mt-2">
                    <span className="text-[12px] text-gray-500 font-semibold">Link for book an appointment</span>
                    <div className="flex flex-row items-center border border-gray-500 mt-2 w-full h-[50px] rounded-[10px] focus:border-[#0097DB]">
                        <input
                            type="text"
                            // name="fullName"
                            // value={formData.fullName}
                            // onChange={handleInputChange}
                            // required
                            className="text-[14px] pl-2 text-black placeholder-black focus:outline-none w-full h-[40px]"
                        />
                    </div>
                </div>
            </div>
            <div className="flex md:flex-row flex-col md: justify-between w-[90%] items-center mt-4">
                <div className="md:w-[48%] w-full flex flex-col">
                    <span className="text-gray-500 text-[14px] italic text-[Gilroy-SemiBold]">How to use:</span>
                    <span className="text-gray-500 text-[14px] text-[Gilroy-SemiBold]">Click on the to enable search. On enabling the field you can either choose the current location, or enter the name of the location,
                        nearest landmark or select from the results. You can then move the map around with location icon being constant in the center. Choose
                        confirm and save the coordinates the location of the OPD block where you want to patient to reach. Use the and-buttons to zoom in and zoom out. Click on Done to</span>
                </div>
                <div className="md:w-[48%] w-[full]">
                    <img className="w-full h-[250px] rounded-md" src="https://s3-alpha-sig.figma.com/img/e8f4/3429/5f6afbcb536ca4adff9c50d4b007bb7f?Expires=1737331200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nzWlzTZiLDL-IaDmBhvLu3Frr1wXWkTwc3tSOU1RDKotIq8uETFcHffktPI9UOEYCin4WW6CwP4BuWAZNxBlr~yWV5~t0oKurBhNLTBerQbpBOC6h1DBSrBLXyJd-L51glDipkvV0MvGRmun~SeQQzy7ExC0SeRnztTPgJbe4m~xRLKRA4ztYcVkinZikS~2q5bOACZ1M0Ov4IkVWSKIWM6LhsROwABEkfG-AlG5Hcm3pz6PCSKGmJ3QRWeyUSnb5mE4Kk02PPD1bnj8j9wYLhr09NZ~R9oEgNoxjGBZ8JVjYbUmhOo1Ex69zAya8fdK31iJ36Fu11ycaO5RtSzepw__" />
                </div>
            </div>
            <div className="flex flex-row items-center justify-end w-[90%] mt-8 pb-4">
                {/* <button className="flex flex-row items-center w-[100px] h-[40px] rounded-md justify-center bg-[#B8B8B8]">
                    <span className="text-sm text-[white]">Save Draft</span>
                </button> */}
                {!props.loading && <button onClick={props.onSave} className="flex flex-row items-center w-[100px] h-[40px] rounded-full justify-center bg-gray-400 ml-4">
                    <span className="text-sm text-[white]">Done</span>
                </button>}
            </div>
        </div>
    )
}